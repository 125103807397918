
<template>
  <v-app>
    <x-navigation class="d-print-none" ref="navigation"></x-navigation>

    <v-app-bar
      color="#3899da" dark
      style="border-bottom:1px solid rgba(0,0,0,.12) !important;"
      app fixed clipped-left text
      class="d-print-none"
    >
      <v-app-bar-nav-icon @click.stop="toggleNavigation" text></v-app-bar-nav-icon>

      <img src="/img/20200301_occ_solution_logo.svg" style="height: 80%;" class="pl-2" alt="OCC - Solutions logo"/>

      <v-spacer></v-spacer>

      <v-menu>
        <template v-slot:activator="{ on }">
          <div v-show="showSelectorCurrency">
            <v-select dense hide-details
              v-on="on"
              :items="currencies"
              :menu-props="{ top: false, offsetY: true }"
              return-object
              item-text="name"
              :label="$t('currencies.currencies')"
              class="styled-select mt-2 mb-2 mx-3"
              data-vv-name="select"
              v-model="currencySelected"
              @change="currencyChange"
            ></v-select>
          </div>
        </template>
      </v-menu>

      <v-select dense hide-details
        v-model="language"
        :items="languages"
        :menu-props="{ top: false, offsetY: true }"
        class="styled-select mt-2 mb-2 mx-3"
        prepend-icon="translate"
        @change="changeLocale"
      ></v-select>

      <v-menu
        v-model="openMenu"
        :nudge-width="296"
        :nudge-bottom="40"
        :nudge-left="295"
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            <v-icon large>mdi-apps</v-icon>
          </v-btn>
        </template>

        <v-card tile style="max-width: 22.7em">
          <v-row no-gutters v-if="loadingProducts">
            <v-col cols="12" class="py-12 text-center">
              <v-progress-circular indeterminate
                :width="4"
                :size="54"
                color="primary"
                class="mx-auto"
              ></v-progress-circular>
              <p class="mt-1 mb-0 pl-3 caption grey--text text--darken-2">
                {{ $t('waiting.title') }}...
              </p>
            </v-col>
          </v-row>
          <v-list v-else style="max-height: 77vh" class="py-0 overflow-y-auto">
            <v-list-item>
              <v-list-item-content>
                <v-row>
                  <template v-for="item in products">
                    <v-col cols="12" sm="6" :key="`prod${item.id}`" style="cursor: pointer">
                      <a :href="item.url">
                        <img :src="item.logo || item.defaultLogo"
                          alt="System Logo"
                          :style="item.name === 'occ'
                            ? 'max-height: 50px; max-width: 150px; margin-left: 1em;margin-top: 1.5em;'
                            : item.name.includes('LIDERAZGO')
                              ? 'margin-top: 0.5em; max-width: 160px'
                              : 'max-width: 150px'
                          "
                        />
                      </a>
                    </v-col>
                  </template>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <v-tooltip left v-if="['customer', 'enterprise_admin'].includes(user.role)">
        <template v-slot:activator="{ on }">
          <v-btn :href="helpUrl" class="elevation-0" text fab  target="_blank" v-on="on">
            <v-icon>mdi-help-circle</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('manual') }}</span>
      </v-tooltip>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <x-badge
              color="red"
              :overlap="true"
              :content="!notifications || !notifications.unread_number ? '0' :
                (notifications.unread_number < 100 ? notifications.unread_number : '+99')"
            >
              <v-icon>notifications</v-icon>
            </x-badge>
          </v-btn>
        </template>
        <v-list style="max-width: 28em">
          <v-list-item
            v-for="(notification, index) in notifications.last_notifications"
            :key="index"
            :class="!notification.read ? 'unread' : ''"
          >
            <template v-if="user.role === 'admin'">
              <v-list-item-title @click="readNotification(notification)" v-if="notification.sourceType === 'enterprise_plan'">
                <router-link
                  :to="notification.action"
                  class="no-decoration"
                  :class="!notification.read ? 'font-unread' : 'read'"
                >
                  {{ $t(`notifications.${notification.type}`, {enterprise: notification.source.name}) }}
                </router-link>
              </v-list-item-title>
              <v-list-item-title @click="readNotification(notification)" v-else>
                <router-link
                  :to="notification.action"
                  class="no-decoration"
                  :class="!notification.read ? 'font-unread' : 'read'"
                >
                  {{ $t(`notifications.${notification.type}`, {enterprise: notification.source.enterprise.name, poll: notification.source.name}) }}
                </router-link>
              </v-list-item-title>
            </template>
            <template v-else>
              <v-list-item-title @click="readNotification(notification)">
                <router-link
                  :to="notification.action"
                  class="no-decoration"
                  :class="!notification.read ? 'font-unread' : 'read'"
                >
                  {{ $t(`notifications.${notification.type}`) }}
                </router-link>
              </v-list-item-title>
            </template>
          </v-list-item>
          <v-list-item class="see-more-btn" @click="$router.push('/notifications')">
            <v-list-item-title class="seemore">
              <router-link
                to="/notifications"
                class="no-decoration"
              >
                {{$t('notifications.see_more')}}
              </router-link>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- <v-select
        class="styled-select mr-3"
        :items="languages"
        v-model="language"
        dense
        @change="changeLocale"
      ></v-select> -->

      <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" class="elevation-0" fab color="white">
            <span class="headline primary--text">{{ user.email | initials }}</span>
          </v-btn>
        </template>
        <v-card>
          <v-divider></v-divider>
          <v-list dense>
            <template v-if="user.role !== 'employee'">
              <v-list-item>
                <v-row>
                  <v-col md="3">
                    <div style="background-color: #3899da; width: 52px; height: 52px; padding-left: 2px; padding-top: 2px; border-radius: 50%">
                      <v-avatar color="white" size="48">
                        <span class="headline">{{ user.email | initials }}</span>
                      </v-avatar>
                    </div>
                  </v-col>
                  <v-col md="9">
                    <p>
                      <span class="text-capitalize">
                        {{
                          user.role === 'customer'
                            ? user.customer.name
                            : user.role === 'enterprise_admin'
                              ? 'Co-Admin.'
                              : 'Super Admin.'
                        }}
                      </span><br>
                      <span style="font-size: .9em">{{ user.email }}</span>
                    </p>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item class="text-center" @click="goProfile" v-if="user.role === 'customer' && user.customer.type === 'commercial'">
                <v-list-item-title>
                  <v-icon small>fa-eye</v-icon>
                  {{ $t('input.view_profile') }}
                </v-list-item-title>
              </v-list-item>
              <template @click="secondMenu = !secondMenu">
              <change-enterprise-menu :menu="secondMenu" :enterprises="enterprises" :user="user" @search-enterprises="searchEnterprsises" v-if="user.role === user.view"/>
              </template>
              <v-divider></v-divider>
            </template>
            <v-list-item class="text-center" @click="confirmLogout = true">
              <v-list-item-title style="color: #3899dac7">
                <v-icon color="#3899dac7" small>mdi-logout-variant</v-icon>
                {{ $t('input.logout') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container fluid fill-height :class="{'pa-0': $vuetify.breakpoint.xs}">
        <v-layout :class="{'pa-0': $vuetify.breakpoint.xs}" justify-center>
          <router-view />
        </v-layout>
      </v-container>
    </v-main>

    <v-footer app inset
      color="white"
      class="d-print-none py-0" style="border-top:1px solid rgba(0,0,0,.12) !important;"
    >
      <v-row justify="end" class="my-1">
        <span class="black--text">&nbsp;&nbsp;{{ $t('copyright') }}.</span>
      </v-row>
    </v-footer>

    <x-alert></x-alert>
    <x-help-modal></x-help-modal>

    <!-- T&C not accepted dialog -->
    <x-terms-not-accepted
      @acceptNewTerms="acceptNewTerms"
      :user="user"
      :loading="loadingChangeTerms"
      @changeLoading="($evemt) => { this.loadingChangeTerms = $evemt }"
      v-if="user && user.id && user.role === 'customer' && !user.hasLastTerms"
    />

    <!-- Data Protection Policies not accepted dialog -->
    <x-data-protection-policies-not-accepted
      v-if="user && user.id && user.role === 'customer' && user.hasLastTerms && !user.hasLastPolicy"
      :user="user"
      :loading="loadingChangePolicies"
      @changeLoading="($event) => { this.loadingChangePolicies = $event }"
      @acceptNewPolicies="acceptNewPolicies"
    />
    <v-dialog v-model="showSnackbarDialog" width="40em">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{ $t('auth.close_session') }}
        </v-card-title>
        <v-card-text>{{ $t(snackMsg) }}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="flex-wrap: wrap">
          <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
          <template v-if="doubleOrigin">
            <v-btn color="#eb604c" text to="/wp/cfs/customer">
              <strong>{{ $t('auth.close_session_as_customer') }} </strong>
            </v-btn>
            <v-btn class="ml-0" color="#eb604c" text to="/wp/cfs/enterprise">
              <strong>{{ $t('auth.close_session_as_enterprise') }} </strong>
            </v-btn>
          </template>
          <v-btn v-else color="#eb604c" text to="/wp/cfs">
            <strong>{{ $t('auth.close_session') }} </strong>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      v-if="showSnackbar"
      color="#424242"
      dark fixed bottom right fab
      @click="showSnackbarDialog = true"
      class="mb-10 mr-3"
    >
      <v-icon>mdi-logout</v-icon>
    </v-btn>
    <!-- Logout Confirmation -->
    <x-confirm-logout
      :show.sync="confirmLogout"
      @close="confirmLogout = false"
    ></x-confirm-logout>
  </v-app>
</template>

<script>

import { mapState } from 'vuex'

import NotificationService from '../services/notifications'
import enterpriseService from '../services/enterprises'
import dataProtectionPoliciesService from '../services/data-protection-policies'
import termsAndConditionsService from '../services/terms-and-conditions'
import productsService from '../services/products'
import DashboardService from '../services/dashboard'
import currencyService from '../services/currencies'
import customerService from '../services/customers'

import ChangeEnterpriseMenu from './customers/change-enterprise-menu.vue'

import { localize } from 'vee-validate'

function loadLocale (code) {
  return import(`vee-validate/dist/locale/${code}.json`).then(locale => {
    locale.messages.required = code === 'es'
      ? 'Este campo es obligatorio'
      : 'This field is required'
    localize(code, locale)
  })
}

export default {
  components: {
    ChangeEnterpriseMenu
  },
  data () {
    return {
      openMenu: false,
      confirmLogout: false,
      helpUrl: process.env.VUE_APP_HELP_URL,
      menu: false,
      languages: [],
      language: 'es',
      notifications: this.getNotifications(),
      showSnackbar: false,
      showSnackbarDialog: false,
      doubleOrigin: false,
      snackMsg: '',
      loadingProducts: true,
      products: [],
      options: {
        filter: null,
        search: null
      },
      enterprises: null,
      secondMenu: false,
      loadingChangeTerms: false,
      loadingChangePolicies: false,
      currencies: [],
      currencySelected: {}
    }
  },
  computed: {
    ...mapState({
      user: state => state.session.user,
      storeCurrency: state => state.session.currency,
      showSelectorCurrency: state => state.session.showSelectorCurrency
    })
  },
  watch: {
    openMenu (val) {
      if (val && !this.products.length) {
        setTimeout(() => {
          this.loadProducts()
        }, 1000)
      }
    }
  },
  methods: {
    getProducts () {
      const roles = ['customer', 'enterprise_admin']
      if (this.user.role === 'admin' && this.user.role === this.user.view) {
        return productsService.listActive()
          .then(res => { this.products = res })
      } else if (roles.includes(this.user.role) || roles.includes(this.user.view)) {
        return productsService.listByCustomer()
          .then(res => { this.products = res })
      } else if (this.user.role === 'employee') {
        return DashboardService.getInfo()
          .then(res => { this.products = res.products })
      }
    },
    loadProducts () {
      this.getProducts()
        .then(() => {
          let imageLoaded = 0
          for (const prod of this.products) {
            const img = new Image()
            img.src = prod.logo || prod.defaultLogo
            img.onload = () => {
              imageLoaded++
              if (imageLoaded === this.products.length) {
                setTimeout(() => {
                  this.loadingProducts = false
                }, 400)
              }
            }
          }
        })
    },
    goProfile () {
      this.$router.push('/commercial-profile')
      this.menu = !this.menu
    },
    toggleNavigation () {
      this.$refs.navigation.toggle()
    },
    changeLocale () {
      this.$store
        .dispatch('session/changeLocale', this.language)
        .then(() => {
          this.$i18n.locale = this.language
          this.$vuetify.lang.current = this.language
          loadLocale(this.$i18n.locale)
        })
    },
    fetchEnterprises () {
      return enterpriseService.list(this.options)
        .then((res) => {
          this.enterprises = res.items
          this.enterprises.slice(0, 5)
        })
    },
    searchEnterprsises (tag) {
      this.options.search = tag
      return enterpriseService.list(this.options).then((res) => {
        this.secondMenu = true
        this.enterprises = res.items
        this.enterprises.slice(0, 5)
      })
    },
    async readNotification (notification) {
      await NotificationService.readNotification(notification.id).then(
        response => {
          this.getNotifications()
        }
      )
    },
    getNotifications () {
      return NotificationService.list()
        .then((res) => {
          this.notifications = res
        })
        .catch(err => { this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`)) })
    },
    closeMenu () {
      this.menu = !this.menu
    },
    acceptNewTerms (customerId, termsId) {
      this.loadingChangeTerms = !this.loadingChangeTerms
      return termsAndConditionsService.acceptByCustomer({ customerId, termsId })
        .then(() => {
          this.user.hasLastTerms = !this.user.hasLastTerms
          this.$store.dispatch('session/updateEnterprise', this.user)
          this.loadingChangeTerms = !this.loadingChangeTerms
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    acceptNewPolicies (customerId, policyId) {
      this.loadingChangePolicies = !this.loadingChangePolicies
      return dataProtectionPoliciesService.acceptByCustomer({ customerId, policyId })
        .then(() => {
          this.user.hasLastPolicy = !this.user.hasLastPolicy
          this.$store.dispatch('session/updateEnterprise', this.user)
          this.loadingChangePolicies = !this.loadingChangePolicies
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    currencyChange () {
      if (this.user.customer !== null) {
        customerService.changeCurrency({ id: this.user.customer.id, currencyId: this.currencySelected.id })
      }
      this.$store.dispatch('session/setCurrency', this.currencySelected)
    }

  },
  created () {
    const languages = this.$t('languages')
    for (const key in languages) {
      this.languages.push({ value: key, text: languages[key] })
    }
    this.language = this.user.lang
    this.$i18n.locale = this.language
    loadLocale(this.$i18n.locale)
    this.getNotifications()
    if (this.user.role === 'customer' && this.user.customer.type === 'commercial') {
      this.fetchEnterprises()
    }
    if (this.user.origin) {
      this.showSnackbar = true
      this.doubleOrigin = !!this.user.origin.origin
      if (this.user.role === 'customer' && this.user.customer.type === 'commercial' && this.user.enterprise) {
        this.snackMsg = this.doubleOrigin ? 'auth.logged_as_enterprise_superadmin' : 'auth.logged_as_enterprise'
      } else {
        this.snackMsg = 'auth.logged_as_customer'
      }
    }

    currencyService.getActives()
      .then(res => {
        this.currencies = res.data
        this.currencies.map(item => {
          if (this.user.role === 'admin') {
            if (item.isBase) this.currencySelected = item
          } else {
            if (this.user.customer && item.id === this.user.customer.currencyId) this.currencySelected = item
          }
        })
        this.$store.dispatch('session/setCurrency', this.currencySelected)
      })
  },
  mounted () {
    const urlString = window.location.href
    const url = new URL(urlString)
    const updatedAt = url.searchParams.get('updatedAt')
    if (updatedAt) {
      console.log('Updated at 2024-12-16 18:00')
    }
  }
}
</script>

<style scoped>

.styled-select,
.styled-select label[for] {
  max-width: 140px;
  font-size: 10pt;
}

.unread {
  background-color: #3899da47;
}

.unread:hover {
  background-color: white;
}

.seemore {
  text-align: center;
}

.no-decoration {
  text-decoration: none;
}

.font-unread {
  color: midnightblue;
}

.see-more-btn {
  background-color: #e0e0e087;
}

.see-more-btn:hover {
  background-color: aliceblue;
}

.read {
  cursor: auto;
}
</style>
